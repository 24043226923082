import {createSlice} from "@reduxjs/toolkit"

const translations = createSlice({
    name: "@@TRANSLATIONS",
    initialState: {
        selectedLanguages: [],
    },
    reducers: {
        SET_SELECTED_LANGUAGES: (state, action) => {
            state = {
                ...state,
                selectedLanguages: action.payload,
            }
            return state
        },
    },
});

export const {
    SET_SELECTED_LANGUAGES,    
} = translations.actions;

export default translations.reducer
