import React, {useEffect} from 'react';
import {PlusCircle as PlusIcon} from 'react-feather';
import {CircularProgress, IconButton, Tooltip, Box} from "@mui/material";
import Clipboard from "./Clipboard";
import useClipboard from "../../hooks/useClipboard";
import {connect} from "react-redux";
import ManagementDialog from "./Dialog";
import useFormatDate from "../../hooks/useFormatDate";
import clipboardAPI from '../../services/clipboardAPI';

const Clipboards = ({}) => {
    const {repertorization, list, addClipboard, consultationId, isLoading} = useClipboard();
    const consultation = repertorization?.consultation;

    const formattedDate = useFormatDate(consultation?.date);

    useEffect(() => {
        list({})
    }, [consultationId])

    const patient = consultation?.patient

    const text = patient ? `${patient.name} ${patient.surname} ${formattedDate}` : 'No consultation assigned'

    return (
        <>
            {!isLoading ?
                <>
                    <Box sx={{marginLeft: '.5rem'}}>
                        <Clipboard
                            clipboard={{
                                id: -1,
                                name: "All clipboards",
                                number: -1,
                                rubrics: [],
                                toDelete: false,
                                userId: -1,            
                                                
                            }}
                            key={-1}
                        />
                    </Box>
                    
                    {repertorization?.clipboards?.map((clipboard, index) =>
                        <Clipboard
                            clipboard={clipboard}
                            key={index}
                        />
                    )}
                    
                    {/* moved inside /Clipboards/Dialog.js
                    <IconButton
                        onClick={() => addClipboard()}
                        sx={{color: 'white'}}
                    >
                        <PlusIcon/>
                    </IconButton>
                    */}

                    <ManagementDialog text={text}/>

                </>
                :
                <CircularProgress
                    size={30}
                    color={'secondary'}
                    thickness={4}
                    disableShrink
                />
            }
        </>
    )
};

export default connect(
    ({}) => ({}),
)(Clipboards);
