import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import clipboardAPI from "../services/clipboardAPI";
import useAuth from "../hooks/useAuth";
import repertorizationAPI from "../services/repertorizationAPI";

const initialAuthState = {
    clipboards: [],
    showRemedies: true,
    repertorization: {
        clipboards: []
    },
    strategy: 'count'
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const {repertorization,  isLoading} = action.payload;
            return {
                ...state,
                repertorization: repertorization || {},
                isLoading
            };
        }
        case 'ADDCLIPBOARD': {
            const {clipboard} = action.payload;
            const prevClips = state.repertorization?.clipboards || []
            return {
                ...state,
                repertorization:{
                    ...state.repertorization,
                    clipboards: [...prevClips, clipboard]
                }
            };
        }
        case 'CHANGESTRATEGY': {
            const {strategy} = action.payload;

            return {
                ...state,
                strategy
            };
        }
        case 'CHANGESHOWREMEDIES': {
            const {showRemedies} = action.payload;

            return {
                ...state,
                showRemedies
            };
        }
        case 'ASSIGN': {
            const {consultationId} = action.payload;
            return {
                ...state,
                consultationId
            };
        }
        case 'ADDCLIPBOARDRUBRIC': {
            const {clipboardId, clipboardRubric} = action.payload;

            const clipboards = state.repertorization?.clipboards || [];

            clipboards.map(clip =>
                clip.id == clipboardId && clip.rubrics.push(clipboardRubric)
            );

            const repertorization = {
                ...state.repertorization,
                clipboards: clipboards
            }

            return {
                ...state,
                repertorization
            };
        }
        default: {
            return {...state};
        }
    }
};

const ClipboardContext = createContext({
    ...initialAuthState,
});

export const ClipboardProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const {user} = useAuth();

    const assign = ({consultationId}) => {
        dispatch({
            type: 'ASSIGN',
            payload: {
                consultationId
            }
        });
    }

    const list = async () => {
        try {
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isLoading: true
                }
            });
            const result = await repertorizationAPI.list({ consultationId: state.consultationId});
            dispatch({
                type: 'INITIALISE',
                payload: {
                    repertorization: result.data
                }
            });
        } catch (e) {
            dispatch({
                type: 'INITIALISE',
                payload: {}
            });
        }
    };

    const addClipboard = async (params) => {
        const prevClipboards = state.repertorization?.clipboards
        const number = prevClipboards?.[prevClipboards?.length - 1]?.number + 1 || 1;
        try {
            const result = await clipboardAPI.add({
                name: `clipboard${number}`,
                number: number,
                repertorizationId: state.repertorization?.id,
                ...params,
            });
            dispatch({
                type: 'INITIALISE',
                payload: {
                    repertorization: result.data
                }
            });
        } catch (e) {
            dispatch({
                type: 'INITIALISE',
                payload: {
                    repertorization: result.data
                }
            });
        }
    };

    const assignRepertorization = async (consultationId) => {
        try {
            console.log("consultationId", consultationId)
            const result = await repertorizationAPI.assignRepertorization(state.repertorization.id, consultationId)

            dispatch({
                type: 'INITIALISE',
                payload: {
                    repertorization: result.data.repertorization
                }
            });
        } catch (e) {
            dispatch({
                type: 'INITIALISE',
                payload: {
                    repertorization: {}
                }
            });
        }
    };


    const addClipboardRubric = async ({clipboardId, clipboardRubric}) => {
        const result = await clipboardAPI.addRubric({
            id: clipboardId,
            clipboardRubric: clipboardRubric
        });

        dispatch({
            type: 'ADDCLIPBOARDRUBRIC',
            payload: {
                clipboardId,
                clipboardRubric: result.data?.clipboardRubric
            }
        });
    };
    const changeStrategy = (strategy) => {

        dispatch({
            type: 'CHANGESTRATEGY',
            payload: {
                strategy
            }
        });
    };

    const changeShowRemedies = (showRemedies) => {

        dispatch({
            type: 'CHANGESHOWREMEDIES',
            payload: {
                showRemedies
            }
        });
    };

    return (
        <ClipboardContext.Provider
            value={{
                ...state,
                changeShowRemedies,
                addClipboard,
                assignRepertorization,
                addClipboardRubric,
                assign,
                changeStrategy,
                list
            }}
        >
            {children}
        </ClipboardContext.Provider>
    );
};

export default ClipboardContext;
