import {React, useState, useCallback} from "react";
import {Badge, IconButton} from "@mui/material";
import {Clipboard as ClipboardIcon} from "react-feather";
import {useDrag, useDrop} from "react-dnd";
import useAuth from "../../hooks/useAuth";
import {SET_SELECTED_CLIPBOARD} from "../../redux/tabs";
import {connect} from "react-redux";
import {useSnackbar} from "notistack";
import useClipboard from "../../hooks/useClipboard";
import { transform } from "lodash";
import {useTheme} from "@mui/material"
import Assignment from '@mui/icons-material/Assignment'

const Clipboard = ({clipboard, setSelectedClipboard}) => {

    const theme = useTheme()
    const defaultIconStyle = { color: 'white', transform: 'none'}
    const onDragIconStyle = { color: theme.palette.primary.main, backgroundColor: 'white', transform: 'scale(1.35)'}

    const {addClipboardRubric} = useClipboard();
    const {enqueueSnackbar} = useSnackbar();
    const [isIconHover, setIsIconHover] = useState(false)
    
    const selectClip = () => {
        setSelectedClipboard({id: clipboard.id, name: clipboard.name})
    };
    
    const [{isActive}, drop] = useDrop(() => ({
        accept: 'rubric',
        collect: (monitor) => ({
            isActive: monitor.canDrop() && monitor.isOver(),
        }),
        drop(item, monitor) {
            handleDrop(item)
        },
    }))
    
    const [{opacity}, dragRef] = useDrag(
        () => ({
            type: 'rubric',
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1
            }),
            item: clipboard
        }),
        []
    )

    const handleDragHover = useCallback((e, hovering) => {
        e.stopPropagation()
        e.preventDefault()
        setIsIconHover(hovering)
    }, [isIconHover])

    const handleDrop = async (rubric) => {
        try {
            await addClipboardRubric({
                clipboardId: clipboard.id,
                clipboardRubric: {rubric: rubric, intensity: 1}
            })
            enqueueSnackbar('Rubric added', {
                variant: 'success'
            });
        } catch (e) {
            if (Object.keys(e) == 0) return
            enqueueSnackbar('Rubric already added', {
                variant: 'error'
            });
        }

        setIsIconHover(false)
    }
    return (
        <>
        {
            clipboard?.id == -1 ?
                <IconButton
                    sx={{
                        transition: 'all 0.2s ease-in-out',
                        ...(isIconHover ? onDragIconStyle : defaultIconStyle),
                    }}
                    id={`clip-${clipboard.id}`}
                    onClick={selectClip}
                >
                    <Badge badgeContent={clipboard?.rubrics?.length || 0} color={"secondary"}>
                        <Assignment />
                    </Badge>
                </IconButton>
                :
                <div ref={drop} key={clipboard.id} style={{marginLeft: '0.4rem', marginRight: '0.4rem'}}
                    onDragOver={e => handleDragHover(e, true)}
                    onDragLeave={e => handleDragHover(e, false)}
                    onDrop={e => handleDrop(e)}
                    >
                    <IconButton
                        sx={{
                            transition: 'all 0.2s ease-in-out',
                            ...(isIconHover ? onDragIconStyle : defaultIconStyle),
                        }}
                        tabIndex={-1}
                        id={`clip-${clipboard.id}`}
                        onClick={selectClip}
                        ref={dragRef}
                        style={{opacity}}
                    >
                        <Badge badgeContent={clipboard?.rubrics?.length || 0} color={"secondary"}>
                            <ClipboardIcon/>
                        </Badge>
                    </IconButton>
            </div>
            }
        </>
    )
}

export default connect(
    ({search: {repertories = {}}}) => ({repertories}),
    (dispatch) => ({
        setSelectedClipboard: (payload) => dispatch(SET_SELECTED_CLIPBOARD(payload)),
    })
)(Clipboard);
