import {configureStore} from "@reduxjs/toolkit";
import tabs from "./redux/tabs";
import repertories from "./redux/repertories";
import app from "./redux/app";
import search from "./redux/search";
import rubrics from "./redux/rubrics";
import patients from "./redux/patients";
import repertorySettings from "./redux/repertorySettings";
import translations from "./redux/translations";
import config from "./redux/config";

const store = configureStore({
    reducer: {tabs, repertories, app, search, config, repertorySettings, rubrics, patients, translations},
});

export default store;
